import React from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { APP } from 'assets/Constants'

export const HomePage: React.FC = () => {
  const cookies = new Cookies()
  const history = useHistory()
  const token = cookies.get('api_tempkey')

  if (!token) window.location.href = APP.OAUTH_URL
  else history.push('dashboard')
  return null
}
