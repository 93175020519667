import React from 'react'
import { Grid } from '@chakra-ui/react'

import { InfoCard } from './InfoCard'

type SiteCountCardsProps = {
  subBg: string
  bg: string
  data: {
    Site: [
      {
        SiteId: number
        SiteName: string
        Country: string
        State: string
        City: string
        RfidCount: number
      },
    ]
    Count: string
  }
  onClickpass?: (index: number) => void
}

export function SiteCountCards({ subBg, data, onClickpass }: SiteCountCardsProps): JSX.Element {
  if (!data) return <div></div>
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={2}>
      <InfoCard subBg="#828D9E" color="white" mainText={data.Count} subText="Total" />
      {data.Site.map((item, index) => (
        <InfoCard
          key={`InfoCard_${index}`}
          onClickProp={() => {
            if (onClickpass) onClickpass(index)
          }}
          subBg={subBg}
          mainText={item.RfidCount.toString()}
          subText={item.SiteName}
        />
      ))}
    </Grid>
  )
}
