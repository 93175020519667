import { Box, Center, Flex, Modal, ModalContent, ModalOverlay, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { InfoIcon } from '@chakra-ui/icons'

type ConfirmationModalProps = {
  message: string
  isOpen: boolean
  onClose: () => void
  onYes?: () => void
}

export const ConfirmationModal = ({ message, isOpen, onClose, onYes }: ConfirmationModalProps): JSX.Element => {
  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent w="448px" h="314px" borderTopColor="blue.500" borderTopWidth="8px" borderRadius="8px">
          <Flex h="100%" flexDirection="column" borderRadius="80px">
            <Flex d="flex" h="full" mx={8} alignItems="center" justifyContent="center" flexDirection="column">
              <InfoIcon h="30px" w="30px" color="blue.500" />
              <Box mt={8}>
                <Center>
                  <Text textAlign="center" fontSize="32px" lineHeight="48px" fontWeight="300">
                    {message}
                  </Text>
                </Center>
              </Box>
            </Flex>
            <Flex w="full" borderTopWidth="0.5px" borderColor={bg}>
              <Box
                borderRightWidth="1px"
                borderColor={bg}
                d="flex"
                w="50%"
                h="64px"
                color="blue.500"
                fontSize="14px"
                fontWeight="600"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                borderBottomLeftRadius="8px"
                bg={subBg}
                onClick={onYes}
                _hover={{
                  bg: bg,
                }}
              >
                Yes
              </Box>
              <Box
                borderLeftWidth="0.5px"
                borderColor={bg}
                d="flex"
                w="50%"
                h="64px"
                fontSize="14px"
                fontWeight="400"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                borderBottomRightRadius="8px"
                bg={subBg}
                onClick={onClose}
                _hover={{
                  bg: bg,
                }}
              >
                No
              </Box>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}
