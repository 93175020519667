import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { APP } from 'assets/Constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProtectedRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const cookies = new Cookies()
  const token = cookies.get(APP.COOKIE_NAME)

  return (
    <Route {...rest} render={(props) => (token ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)} />
  )
}
