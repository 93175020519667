import React from 'react'
import { Box, Text, Center, SlideFade } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

type LocationListItemProps = {
  bg: string
  borderColor: string
  siteName: string
  location: string
  onClickCallback?: () => void
}

export const LocationListItem = ({
  bg,
  borderColor,
  siteName,
  location,
  onClickCallback,
}: LocationListItemProps): JSX.Element => {
  return (
    <SlideFade in={true} offsetY={40}>
      <Box
        d="flex"
        w="full"
        h="48px"
        px={8}
        py={1}
        borderBottom="2px"
        borderColor={borderColor}
        bg={bg}
        justifyContent="space-between"
        cursor="pointer"
        onClick={onClickCallback}
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Box h="full">
          <Text isTruncated fontSize="14px" fontWeight="400" lineHeight="20px">
            {siteName}
          </Text>
          <Text isTruncated fontSize="12px" fontWeight="400" lineHeight="16px">
            {location}
          </Text>
        </Box>
        <Center>
          <ChevronRightIcon />
        </Center>
      </Box>
    </SlideFade>
  )
}
