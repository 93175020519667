import React from 'react'
import { NavLink as RouteLink, useRouteMatch } from 'react-router-dom'
import { Text, Flex, useColorMode } from '@chakra-ui/react'

type NavLinkProps = { text: string; to: string; activeOnlyWhenExact?: boolean }

export const NavLink = ({ text, to, activeOnlyWhenExact = true }: NavLinkProps): JSX.Element => {
  const { colorMode } = useColorMode()
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  })
  const matchedColor = colorMode === 'light' ? '#0050F2' : 'blue.400'
  const unMatchedColor = colorMode === 'light' ? 'white' : 'gray.800'

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      px="8"
      h="full"
      borderBottom="8px"
      borderColor={match ? matchedColor : unMatchedColor}
    >
      <RouteLink to={to}>
        <Text mt={{ base: 4, md: 0 }} fontSize="md" userSelect="none">
          {text}
        </Text>
      </RouteLink>
    </Flex>
  )
}
