import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Input,
  HStack,
  Button,
  useDisclosure,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { NavLink, useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { ConfirmationModal } from 'components'
import { APP } from 'assets/Constants'

const sitesSaveEndpoint = `${APP.API_URL}site/save`

export const CreateSite = (): JSX.Element => {
  const cookies = new Cookies()
  const history = useHistory()

  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isCreateSiteBtnDisabled, setIsCreateSiteBtnDisabled] = useState(true)
  const [isAPICalled, setIsAPICalled] = useState(false)
  const [inputData, setInputData] = useState({
    SiteName: '',
    State: '',
    Country: '',
    PinCode: '',
    City: '',
    IsActive: 'true',
  })

  const onSuccess = () => {
    if (isAPICalled) return
    setIsAPICalled(true)
    fetch(sitesSaveEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
      body: JSON.stringify(inputData),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAPICalled(false)
        if (res.Response.ResponseCode === '1') history.goBack()
        else {
          setIsAPICalled(false)
          onClose()
          alert(`${res.Response.ResponseMessage}!!!`)
        }
      })
      .catch((error) => alert(error.message))
  }

  const onCancelClick = () => history.goBack()

  const OnInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputData({ ...inputData, [e.target.name]: e.target.value })

  useEffect(() => {
    if (inputData.SiteName && inputData.State && inputData.City && inputData.Country && inputData.PinCode)
      setIsCreateSiteBtnDisabled(false)
    else setIsCreateSiteBtnDisabled(true)
  }, [inputData])

  return (
    <SlideFade in={true} offsetY={40}>
      <Flex h="full" flexDirection="column" bg={bg}>
        <Box h="32px" bg={subBg} px={10} pt="2px" alignItems="center" flexDirection="column" mt="1.5px">
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon ml={4} color="#F251B7" />}>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" as={NavLink} to="/sites">
                Sites
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="600" as={NavLink} to="#">
                Create Site
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
          <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
            <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
              Create new site
            </Heading>
          </Flex>
          <Box h="full" bg={subBg}>
            <Box
              // h="25vh"
              w="full"
              py={10}
              px={4}
              borderColor={bg}
              borderTopWidth={2}
              borderBottomWidth={2}
              alignItems="center"
            >
              <Input
                borderColor="none"
                borderWidth="0px"
                borderBottom="1px"
                borderBottomColor={bg}
                borderRadius="0px"
                px={0}
                focusBorderColor="none"
                w="50vw"
                placeholder="Site Name *"
                size="md"
                onChange={OnInputChange}
                name="SiteName"
              />
              <HStack mt={12} spacing={4}>
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="Site Country *"
                  size="md"
                  onChange={OnInputChange}
                  name="Country"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="State *"
                  size="md"
                  onChange={OnInputChange}
                  name="State"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="City *"
                  size="md"
                  onChange={OnInputChange}
                  name="City"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="Pincode *"
                  size="md"
                  onChange={OnInputChange}
                  name="PinCode"
                />
              </HStack>
            </Box>
            <Box p={8} w="full" d="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                bg={bg}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: '' }}
                fontSize="14px"
                fontWeight="400"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                mx={4}
                bg="#0050F2"
                _hover={{ bg: '#0050F2' }}
                _active={{ bg: '#0050F2' }}
                color="white"
                fontSize="14px"
                fontWeight="600"
                onClick={onOpen}
                disabled={isCreateSiteBtnDisabled}
              >
                Create Site
              </Button>
            </Box>
          </Box>
        </Box>
        <ConfirmationModal
          message="Are you sure you want to create a site?"
          isOpen={isOpen}
          onClose={onClose}
          onYes={onSuccess}
        />
      </Flex>
    </SlideFade>
  )
}
