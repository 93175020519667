import { Box, VStack, Text, SlideFade } from '@chakra-ui/react'
import React from 'react'

type InfoCardProps = { subBg: string; color?: string; mainText: string; subText: string; onClickProp?: () => void }

export const InfoCard = ({ subBg, mainText, subText, color, onClickProp }: InfoCardProps): JSX.Element => {
  const localOnClick = () => {
    if (onClickProp) onClickProp()
  }
  return (
    <SlideFade in={true} offsetY={40}>
      <Box
        bg={subBg}
        color={color}
        w="100%"
        height="10rem"
        cursor={onClickProp ? 'pointer' : 'default'}
        onClick={localOnClick}
      >
        <VStack h="full" pt={4}>
          <Text fontSize="48px" fontWeight={100} lineHeight="72px">
            {mainText}
          </Text>
          <Text isTruncated fontSize="14px" fontWeight="400" lineHeight="20px">
            {subText}
          </Text>
        </VStack>
      </Box>
    </SlideFade>
  )
}
