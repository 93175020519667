import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  VStack,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Center,
  Button,
  Spacer,
} from '@chakra-ui/react'

import { LocationListItem } from './LocationListItem'
import { LotTable } from './LotTable'
import { RFIDTable } from './RFIDTable'
import { ButtonBar } from './ButtonBar'
import { SiteCountCards } from './SiteCountCards'
import { SitesDetailedCards } from './SitesDetailedCards'
import { SiteGetResponseData } from 'types/ApiResponse.types'

import { DownloadExcel } from 'services/DownloadExcel'
import { makePostCall } from 'services/DashboardAPIs'

export const Dashboard: React.FC = () => {
  const history = useHistory()
  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const buttonBarBG = useColorModeValue('#EDF2F7', 'gray.800')
  const [selectedLocationListItem, setSelectedLocationListItem] = useState<number | null>(null)
  const [daysFilterValue, setDaysFilterValue] = useState<{ days: number; startDate?: string; endDate?: string }>({
    days: 7,
    startDate: '',
    endDate: '',
  })
  const [showloader, setShowloader] = useState(true)
  const [dwnlngExcl, setDwnlngExcl] = useState(false)
  const [selectedLot, setSelectedLot] = useState<{ SiteId: number; RfidLotNo: string; LotCount: number } | null>(null)
  const [siteData, setSiteData] = useState<SiteGetResponseData | null>(null)

  const changeSelectedLocationListItem = (index: number) =>
    setSelectedLocationListItem(index !== selectedLocationListItem ? index : null)

  const onClickExportToExcel = useCallback(async () => {
    const SiteId = selectedLocationListItem !== null ? siteData?.Data?.Site[selectedLocationListItem].SiteId : null
    const reqBody: unknown = daysFilterValue.startDate
      ? {
          StartDate: daysFilterValue.startDate,
          EndDate: daysFilterValue.endDate,
          SiteId,
          TimeZoneOffset: new Date().getTimezoneOffset(),
        }
      : { SiteId, Days: daysFilterValue.days, TimeZoneOffset: new Date().getTimezoneOffset() }

    if (!dwnlngExcl) setDwnlngExcl(true)

    try {
      await DownloadExcel(reqBody)
    } finally {
      setDwnlngExcl(false)
    }
  }, [daysFilterValue, selectedLocationListItem])

  useEffect(() => {
    const reqBody: unknown = daysFilterValue.startDate
      ? {
          StartDate: daysFilterValue.startDate,
          EndDate: daysFilterValue.endDate,
        }
      : { Days: daysFilterValue.days }
    const tempAsyncFunc = async () => {
      try {
        const res = await makePostCall<SiteGetResponseData>('site/get', reqBody, history)
        setSiteData(res)
      } catch (err) {
        setSiteData(null)
      } finally {
        setShowloader(false)
      }
    }

    if (!showloader) setShowloader(true)
    tempAsyncFunc()
  }, [daysFilterValue])

  return (
    <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
      <Flex w="full" h={16} pl={8} alignItems="center" bg={subBg}>
        <Text fontSize="24px" fontWeight="350" py={2} userSelect="none">
          Dashboard
        </Text>
      </Flex>
      <Flex flex="1" flexDirection="row" mt={2}>
        <Box d="flex" flexDirection="column" w="30vw" bg={subBg}>
          <Box w="full" borderBottom="2px" borderColor={bg}>
            <Heading py="2" pl={8} fontSize="20px" fontWeight="350" lineHeight="28px" userSelect="none">
              Sites
            </Heading>
          </Box>
          <Box flex="1 1 auto" overflowX="hidden" overflowY="auto" height="0px" pb="2rem" w="full">
            {siteData?.Data &&
              siteData.Data?.Site?.map((item: { SiteName: string; Country: string; City: string }, index: number) => (
                <LocationListItem
                  key={`locationlistitem_${index}`}
                  onClickCallback={() => changeSelectedLocationListItem(index)}
                  bg={selectedLocationListItem !== index ? subBg : bg}
                  borderColor={bg}
                  siteName={item.SiteName}
                  location={item.Country + ', ' + item.City}
                />
              ))}
          </Box>
        </Box>
        <Box ml={2} d="flex" w="full">
          <VStack h="full" w="full">
            <Box d="flex" justifyContent="space-between" alignItems="center" w="full" bg={subBg}>
              <Heading py="2" pl={8} fontSize="20px" fontWeight="350" lineHeight="28px">
                SMARTDRAPES Production
              </Heading>
              <Spacer />
              <Button
                mr={10}
                bg="#0050F2"
                _hover={{ bg: '#0050F2' }}
                _active={{ bg: '#0050F2' }}
                _focus={{ boxShadow: '' }}
                color="white"
                fontSize="14px"
                fontWeight="600"
                size="md"
                w="130px"
                h="32px"
                borderRadius="3px"
                onClick={onClickExportToExcel}
                disabled={dwnlngExcl}
              >
                Export to Excel
              </Button>
              <ButtonBar
                backgrounds={{ bg, subBg }}
                buttonBarBG={buttonBarBG}
                changeDaysFilterValue={setDaysFilterValue}
              />
            </Box>
            <Box flex="1 1 auto" overflowY="auto" height="0px" w="full" pb="2rem">
              {showloader ? (
                <Center h="full" width="full">
                  <Spinner thickness="4px" speed="0.65s" emptyColor={subBg} color="blue.500" size="xl" />
                </Center>
              ) : (
                <>
                  <Box w="full">
                    {(siteData && selectedLocationListItem === null) ||
                    (siteData &&
                      selectedLocationListItem &&
                      siteData?.Data?.Site.length &&
                      selectedLocationListItem > siteData?.Data?.Site.length - 1) ? (
                      <SiteCountCards
                        subBg={subBg}
                        bg={bg}
                        data={siteData?.Data}
                        onClickpass={changeSelectedLocationListItem}
                      />
                    ) : (
                      selectedLocationListItem !== null &&
                      siteData?.Data?.Site[selectedLocationListItem] && (
                        <SitesDetailedCards subBg={subBg} data={siteData?.Data?.Site[selectedLocationListItem]} />
                      )
                    )}
                  </Box>
                  {selectedLocationListItem !== null && siteData?.Data?.Site[selectedLocationListItem] && (
                    <Box d="flex" h="full" w="full" pt={2} flexDirection="row">
                      <Grid h="full" w="full" templateRows="repeat(2, 1fr)" templateColumns="repeat(12, 1fr)" gap={2}>
                        <GridItem rowSpan={2} colSpan={4} bg={subBg}>
                          <LotTable
                            backgrounds={{ bg, subBg }}
                            siteId={siteData?.Data?.Site[selectedLocationListItem]?.SiteId}
                            daysFilterValue={daysFilterValue}
                            setSelectedLot={setSelectedLot}
                          />
                        </GridItem>
                        <GridItem rowSpan={2} colSpan={8} bg={subBg}>
                          {selectedLot && (
                            <RFIDTable
                              backgrounds={{ bg, subBg }}
                              selectedLot={selectedLot}
                              siteId={siteData?.Data?.Site[selectedLocationListItem]?.SiteId}
                              daysFilterValue={daysFilterValue}
                            />
                          )}
                        </GridItem>
                      </Grid>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Box>
  )
}
