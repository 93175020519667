import * as React from 'react'
import { ChakraProvider, Flex, CSSReset, Box } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'

import theme from 'styles/theme'
import Fonts from 'styles/fonts'
import { Header } from 'components'
import { Routes } from 'pages'

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <CSSReset />
        <Fonts />
        <Flex wrap="nowrap" h="100vh" overflow="auto" flexDirection="column">
          <Header />
          <Box flex="1">
            <Routes />
          </Box>
        </Flex>
      </Router>
    </ChakraProvider>
  )
}

export default App
