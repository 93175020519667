import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Input,
  HStack,
  Button,
  RadioGroup,
  Stack,
  Radio,
  Text,
  useDisclosure,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { ConfirmationModal } from 'components'
import { APP } from 'assets/Constants'

const userSaveEndpoint = `${APP.API_URL}user/save`
const usersListEndpoint = `${APP.API_URL}user/list?siteid=`

type UsersListResponseData = {
  Response: {
    ResponseFlag: string
    ResponseCode: string
    ResponseMessage: string
    ResponseDescription: string
  }
  Data: {
    Users: [
      {
        FirstName: 'string'
        LastName: 'string'
        LoginId: 'string'
        IsActive: boolean
        SiteName: 'string'
        SiteId: number
        UserId: number
      },
    ]
    Count: string
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const EditUser = (): JSX.Element => {
  const cookies = new Cookies()
  const history = useHistory()

  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isDeactivateOpen, onOpen: onDeactivateOpen, onClose: onDeactivateClose } = useDisclosure()
  const [radioBtnValue, setRadioBtnValue] = useState('1')
  const [isSaveBtnDisabled, setisSaveBtnDisabled] = useState(false)
  const [isAPICalled, setIsAPICalled] = useState(false)
  const [radioBtnStatus, setRadioBtnStatus] = useState(radioBtnValue === '1' ? 'activate' : 'deactivate')

  const query = useQuery()
  const loginIdProp = query.get('loginid')
  const siteIdProp = query.get('siteid')
  const [values, setValues] = useState<{
    FirstName: string
    LastName: string
    LoginId: string
    SiteName: string
    UserId: number | null
  }>({
    FirstName: '',
    LastName: '',
    LoginId: '',
    SiteName: '',
    UserId: null,
  })
  const { FirstName, LastName, LoginId, SiteName } = values

  const onCancelClick = () => history.goBack()
  const onFirstNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, FirstName: event.target.value })
  const onLastNameValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, LastName: event.target.value })
  const onLoginIdValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, LoginId: event.target.value })

  const onSuccess = () => {
    if (isAPICalled) return
    setIsAPICalled(true)
    fetch(userSaveEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
      body: JSON.stringify({ ...values, IsActive: radioBtnValue === '1' ? 'true' : 'false', SiteId: siteIdProp }),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAPICalled(false)
        if (res.Response.ResponseCode === '1') history.goBack()
        else {
          setIsAPICalled(false)
          onClose()
          alert(`${res.Response.ResponseMessage}!!!`)
        }
      })
      .catch((error) => alert(error.message))
  }

  const resertRadioBtnValues = () => {
    setRadioBtnValue(radioBtnValue === '1' ? '2' : '1')
    onDeactivateClose()
  }

  const onRadioButtonValueChange = (val: string) => {
    setRadioBtnValue(val)
    setRadioBtnStatus(val === '1' ? 'activate' : 'deactivate')
    onDeactivateOpen()
  }

  useEffect(() => {
    if (
      values.FirstName &&
      values.LastName &&
      values.LoginId &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.LoginId)
    )
      setisSaveBtnDisabled(false)
    else setisSaveBtnDisabled(true)
  }, [values])

  useEffect(() => {
    fetch(usersListEndpoint + siteIdProp, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    })
      .then((res) => res.json())
      .then((res: UsersListResponseData) => {
        if (res.Response.ResponseCode === '1') {
          const result = res.Data?.Users?.find((item) => item.LoginId === loginIdProp)
          if (result) {
            setValues({
              FirstName: result.FirstName,
              LastName: result.LastName,
              LoginId: result.LoginId,
              SiteName: result.SiteName,
              UserId: result.UserId,
            })
            setRadioBtnValue(result.IsActive ? '1' : '2')
          }
        }
      })
  }, [])

  return (
    <SlideFade in={true} offsetY={40}>
      <Flex h="full" flexDirection="column" bg={bg}>
        <Box h="32px" bg={subBg} px={10} pt="2px" alignItems="center" flexDirection="column" mt="1.5px">
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon ml={4} color="#F251B7" />}>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" as={NavLink} to="/sites">
                Sites
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" onClick={history.goBack}>
                Users
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="600" as={NavLink} to="#">
                Edit User
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
          <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
            <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
              Edit user
            </Heading>
          </Flex>
          <Box h="full" bg={subBg}>
            <Box w="full" py={10} px={4} borderColor={bg} borderTopWidth={2} borderBottomWidth={2} alignItems="center">
              <Text px={0}>{SiteName}</Text>
              <HStack mt={12} spacing={4}>
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={FirstName}
                  onChange={onFirstNameValueChange}
                  size="md"
                  placeholder="First Name *"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={LastName}
                  onChange={onLastNameValueChange}
                  size="md"
                  placeholder="Last Name *"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={LoginId}
                  onChange={onLoginIdValueChange}
                  size="md"
                  placeholder="Login ID *"
                />
                <Box w="40%"></Box>
                <Box w="40%"></Box>
              </HStack>
              <RadioGroup mt={14} onChange={onRadioButtonValueChange} value={radioBtnValue}>
                <Stack spacing={4} direction="row">
                  <Text mr={7}>Status</Text>
                  <Radio _focus={{ boxShadow: '' }} colorScheme="gray" value="1">
                    Active
                  </Radio>
                  <Radio _focus={{ boxShadow: '' }} colorScheme="gray" value="2">
                    Inactive
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box p={8} w="full" d="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                bg={bg}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: '' }}
                fontSize="14px"
                fontWeight="400"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                mx={4}
                bg="#0050F2"
                _hover={{ bg: '#0050F2', boxShadow: '' }}
                _active={{ bg: '#0050F2', boxShadow: '' }}
                _focus={{ boxShadow: '' }}
                color="white"
                fontSize="14px"
                fontWeight="600"
                onClick={onOpen}
                disabled={isSaveBtnDisabled}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        <ConfirmationModal
          message="Are you sure you want to save the changes?"
          isOpen={isOpen}
          onClose={onClose}
          onYes={onSuccess}
        />
        <ConfirmationModal
          message={`Are you sure you want to ${radioBtnStatus} the user?`}
          isOpen={isDeactivateOpen}
          onClose={resertRadioBtnValues}
          onYes={onDeactivateClose}
        />
      </Flex>
    </SlideFade>
  )
}
