import React, { useState } from 'react'
import {
  Box,
  Heading,
  Flex,
  Image,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  // PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'
import Cookies from 'universal-cookie'

import { Logo, NavLink, ColorModeSwitcher } from 'components'

import userIcon from 'assets/images/user_icn.svg'
import logoutIcon from 'assets/images/logout_icn.svg'
import { APP } from 'assets/Constants'

const logoutEndpoint = `${APP.API_URL}logout`

export const Header: React.FC = (props) => {
  const cookies = new Cookies()
  const token = cookies.get(APP.COOKIE_NAME)
  if (!token) return <div></div>

  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  const onLogoutClick = () => {
    fetch(logoutEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    }).finally(() => {
      window.location.href = APP.LOGOUT_URL
    })
  }

  return (
    <Flex as="nav" h="16" align="center" justify="space-between" wrap="wrap" px="2" {...props}>
      <Flex align="center" mr={10}>
        <Logo pointerEvents="none" />
        <Heading p="4" size="sm" userSelect="none">
          SMARTDRAPES 1.1
        </Heading>
      </Flex>

      {/* Ignore: non responsive app */}
      <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
        <svg fill="teal.500" width="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ base: show ? 'flex' : 'none', md: 'flex' }}
        flexDirection={{ base: 'column', md: 'row' }}
        width={{ base: '100%', md: 'auto' }}
        height="full"
        alignItems="center"
        flexGrow={1}
      >
        <NavLink to="/dashboard" text="Dashboard" />
        <NavLink to="/sites" text="Sites" />
      </Box>

      <Box
        display={{ base: show ? 'flex' : 'none', md: 'block' }}
        mt={{ base: 4, md: 0 }}
        alignItems="center"
        justifyContent="space-around"
        width={{ base: '100%', md: 'auto' }}
      >
        <ColorModeSwitcher />
        <Popover>
          <PopoverTrigger>
            <IconButton
              size="sm"
              fontSize="lg"
              variant="ghost"
              color="current"
              mx={4}
              icon={<Image src={userIcon} cursor="pointer" />}
              aria-label={`Show user data`}
              _active={{ boxShadow: '' }}
              _focus={{ boxShadow: '' }}
            />
          </PopoverTrigger>
          <PopoverContent
            h="50px"
            w="300px"
            _active={{ boxShadow: '', outline: 'none' }}
            _focus={{ boxShadow: '', outline: 'none' }}
          >
            <PopoverArrow />
            <PopoverCloseButton />
            {/* <PopoverHeader>Hello:</PopoverHeader> */}
            <PopoverBody h="50px" w="300px" overflow="auto">
              {`Hello, ${cookies.get('UN') || ''}`}
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <IconButton
          size="sm"
          fontSize="lg"
          variant="ghost"
          color="current"
          mr={4}
          icon={<Image src={logoutIcon} cursor="pointer" />}
          aria-label={`Logout user`}
          _active={{ boxShadow: '' }}
          _focus={{ boxShadow: '' }}
          onClick={onLogoutClick}
          // onClick={onLogoutClick}
        />
      </Box>
    </Flex>
  )
}
