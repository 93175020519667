import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Input,
  HStack,
  Button,
  Select,
  useDisclosure,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { ConfirmationModal } from 'components'
import { APP } from 'assets/Constants'

const userSaveEndpoint = `${APP.API_URL}user/save`
const sitesListEndpoint = `${APP.API_URL}site/list`

type SiteListResponseData = {
  Response: {
    ResponseFlag: string
    ResponseCode: string
    ResponseMessage: string
    ResponseDescription: string
  }
  Data: {
    Users: [
      {
        SiteId: number
        SiteName: string
        SiteUser: number
        Country: string
        State: string
        City: string
        IsActive: boolean
      },
    ]
    Count: string
  }
}

function useIdQuery() {
  return new URLSearchParams(useLocation().search).get('id') || ''
}

export const CreateUser = (): JSX.Element => {
  const cookies = new Cookies()
  const history = useHistory()

  const siteIdProp = useIdQuery()
  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isCreateUserBtnDisabled, setIsCreateUserBtnDisabled] = useState(true)
  const [isAPICalled, setIsAPICalled] = useState(false)
  const [dropDownValue, setDropDownValue] = useState('')
  const [sitesNamesList, setSitesNamesList] = useState<{ SiteName: string; SiteId: number }[]>([])
  const [inputData, setInputData] = useState({
    FirstName: '',
    LastName: '',
    LoginId: '',
    IsActive: 'true',
  })

  const onSuccess = () => {
    if (isAPICalled) return
    setIsAPICalled(true)
    fetch(userSaveEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
      body: JSON.stringify({ ...inputData, SiteId: dropDownValue }),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAPICalled(false)
        if (res.Response.ResponseCode === '1') history.goBack()
        else {
          setIsAPICalled(false)
          onClose()
          alert(`${res.Response.ResponseMessage}!!!`)
        }
      })
      .catch((error) => alert(error.message))
  }

  const onCancelClick = () => history.goBack()

  const OnInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputData({ ...inputData, [e.target.name]: e.target.value })

  const onDropDownValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => setDropDownValue(e.target.value)

  useEffect(() => {
    if (
      dropDownValue &&
      inputData.FirstName &&
      inputData.LastName &&
      inputData.LoginId &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputData.LoginId)
    )
      setIsCreateUserBtnDisabled(false)
    else setIsCreateUserBtnDisabled(true)
  }, [inputData])

  useEffect(() => {
    fetch(sitesListEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    })
      .then((res) => res.json())
      .then((res: SiteListResponseData) => {
        if (res.Response.ResponseCode === '1') {
          const result = res.Data?.Users?.map((item) => {
            return {
              SiteName: item.SiteName,
              SiteId: item.SiteId,
            }
          })
          if (result) {
            setSitesNamesList(result)
            setDropDownValue(siteIdProp)
          }
        }
      })
  }, [])

  return (
    <SlideFade in={true} offsetY={40}>
      <Flex h="full" flexDirection="column" bg={bg}>
        <Box h="32px" bg={subBg} px={10} pt="2px" alignItems="center" flexDirection="column" mt="1.5px">
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon ml={4} color="#F251B7" />}>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" as={NavLink} to="/sites">
                Sites
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" onClick={history.goBack}>
                Users
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="600" as={NavLink} to="#">
                Create User
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
          <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
            <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
              Create new user
            </Heading>
          </Flex>
          <Box h="full" bg={subBg}>
            <Box
              // h="25vh"
              w="full"
              py={10}
              px={4}
              borderColor={bg}
              borderTopWidth={2}
              borderBottomWidth={2}
              alignItems="center"
            >
              <Select variant="flushed" w="48vw" value={dropDownValue} onChange={onDropDownValueChange}>
                {sitesNamesList.map((item, index) => (
                  <option key={`sitesNamesList_${index}`} value={item.SiteId}>
                    {item.SiteName}
                  </option>
                ))}
              </Select>
              <HStack mt={12} spacing={10}>
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="First Name *"
                  size="md"
                  onChange={OnInputChange}
                  name="FirstName"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="Last Name *"
                  size="md"
                  onChange={OnInputChange}
                  name="LastName"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  placeholder="LoginID *"
                  size="md"
                  onChange={OnInputChange}
                  name="LoginId"
                />
                <Box w="40%"></Box>
                <Box w="40%"></Box>
              </HStack>
            </Box>
            <Box p={8} w="full" d="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                bg={bg}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: '' }}
                fontSize="14px"
                fontWeight="400"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                mx={4}
                bg="#0050F2"
                _hover={{ bg: '#0050F2' }}
                _active={{ bg: '#0050F2' }}
                color="white"
                fontSize="14px"
                fontWeight="600"
                onClick={onOpen}
                disabled={isCreateUserBtnDisabled}
              >
                Create User
              </Button>
            </Box>
          </Box>
        </Box>
        <ConfirmationModal
          message="Are you sure you want to create a user?"
          isOpen={isOpen}
          onClose={onClose}
          onYes={onSuccess}
        />
      </Flex>
    </SlideFade>
  )
}
