import React from 'react'
import { Box, Text } from '@chakra-ui/react'

type RFIDListItemProps = {
  borderColor: string
  RFIDStr: string
  timeStamp: string
  referenceNo: string
  device: string
  onClickCallback?: () => void
}

export const RFIDListItem = ({
  borderColor,
  RFIDStr,
  timeStamp,
  referenceNo,
  device,
  onClickCallback,
}: RFIDListItemProps): JSX.Element => {
  return (
    <Box
      onClick={onClickCallback}
      d="flex"
      w="full"
      h="48px"
      pl={1}
      borderBottom="2px"
      borderColor={borderColor}
      justifyContent="space-between"
    >
      <Box h="full" d="flex" alignItems="center" w="full">
        {[RFIDStr, timeStamp, referenceNo, device].map((item) => (
          <Text key={item} flex="1" fontSize="14px" fontWeight="400" lineHeight="20px">
            {item}
          </Text>
        ))}
      </Box>
    </Box>
  )
}
