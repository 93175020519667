import { Box, VStack, Grid, GridItem, Text, SlideFade } from '@chakra-ui/react'
import React from 'react'

type SiteDetailedCardsProps = {
  subBg: string
  data: {
    SiteId: number
    SiteName: string
    Country: string
    State: string
    City: string
    RfidCount: number
    LotCount: number
    DeviceCount: number
  }
}

export function SitesDetailedCards({
  subBg,
  data: { SiteName, City, RfidCount, LotCount, DeviceCount },
}: SiteDetailedCardsProps): JSX.Element {
  const detailsArray = [
    {
      key: 'Devices',
      value: DeviceCount,
    },
    {
      key: 'Total',
      value: RfidCount,
    },
    {
      key: 'Lots',
      value: LotCount,
    },
  ]

  return (
    <SlideFade in={true} offsetY={40}>
      <Grid h="8rem" templateRows="repeat(2, 1fr)" templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem rowSpan={2} colSpan={4} bg={subBg}>
          <Box h="full" pl="43px" pt="36px">
            <Text isTruncated fontSize="20px" fontWeight="350" lineHeight="28px">
              {SiteName}
            </Text>
            <Text isTruncated fontSize="14px" fontWeight="400" lineHeight="20px">
              {City}
            </Text>
          </Box>
        </GridItem>
        <GridItem rowSpan={2} colSpan={8}>
          <Grid h="8rem" templateRows="repeat(2, 1fr)" templateColumns="repeat(9, 1fr)" gap={2}>
            {detailsArray.map((item, index) => (
              <GridItem key={index.toString()} rowSpan={2} colSpan={3} bg={subBg}>
                <VStack h="full" py={2}>
                  <Text isTruncated fontSize="48px" fontWeight="300" lineHeight="72px">
                    {item.value}
                  </Text>
                  <Text isTruncated fontSize="14px" fontWeight="400" lineHeight="20px">
                    {item.key}
                  </Text>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </GridItem>
      </Grid>
    </SlideFade>
  )
}
