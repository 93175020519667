import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Image, Box, Text, Center, Flex, Spinner, SlideFade } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import editIcon from 'assets/images/edit_icn.svg'
import { APP } from 'assets/Constants'
import { SiteListResponseData } from 'types/ApiResponse.types'

type SitesTableProps = { backgrounds: { bg: string; subBg: string } }

const sitesListEndpoint = `${APP.API_URL}site/list`

export function SitesTable({ backgrounds: { bg, subBg } }: SitesTableProps): JSX.Element {
  const cookies = new Cookies()
  const history = useHistory()
  const [siteData, setSiteData] = useState<SiteListResponseData | null>(null)
  const [showloader, setShowloader] = useState(true)

  const onEditBtnClick = (id: number) => history.push(`sites/editsite?id=${id}`)
  const onUsersBtnClick = (id: number, name: string) => history.push(`sites/users?id=${id}&name=${name}`)

  useEffect(() => {
    if (!showloader) setShowloader(true)
    fetch(sitesListEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Response.ResponseCode === '1') setSiteData(res)
        else if (res.Response.ResponseMessage.match(/^(Invalid Request|Invalid Token)/)) {
          cookies.remove(APP.COOKIE_NAME)
          history.push('/')
        }
      })
      .finally(() => setShowloader(false))
  }, [])

  if (showloader)
    return (
      <Center>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.700" color="blue.500" size="xl" />
      </Center>
    )

  return (
    <SlideFade in={true} offsetY={40}>
      <Table variant="simple">
        <Thead bg={bg} h="24px">
          <Tr fontWeight="700" fontSize="10px" lineHeight="12px">
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              Site Name
            </Th>
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              Site User(S)
            </Th>
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              Site Address
            </Th>
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              Status
            </Th>
            <Th py="4px">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {siteData?.Data &&
            siteData.Data?.Users?.map((item, index) => (
              <Tr key={`sitetable_${index}`} p="0px">
                <Td pl={6} py="0" h="48px">
                  {item.SiteName}
                </Td>
                <Td pl={7} py="0" h="48px">
                  <Flex
                    pb="1px"
                    display="inline-block"
                    minW="8%"
                    borderBottomWidth="1px"
                    borderBottomColor="#828D9E"
                    cursor="pointer"
                    onClick={() => onUsersBtnClick(item.SiteId, item.SiteName)}
                  >
                    <Text fontSize="14px" fontWeight="700" color="#828D9E">
                      {item.SiteUser}
                    </Text>
                  </Flex>
                </Td>
                <Td pl={6} py="0" h="48px">
                  {`${item.City}, ${item.Country}`}
                </Td>
                <Td pl={6} py="0">
                  <Box borderRadius={2} h="24px" w="80px" bg={item.IsActive ? 'green.100' : 'red.100'}>
                    <Center>
                      <Text
                        zIndex="2"
                        pt="2px"
                        color={item.IsActive ? '#29C192' : '#ED5353'}
                        fontWeight="700"
                        fontSize="10px"
                      >
                        {item.IsActive ? 'ACTIVE' : 'DEACTIVE'}
                      </Text>
                    </Center>
                  </Box>
                </Td>
                <Td pl={6} py="0" h="48px">
                  <Image src={editIcon} cursor="pointer" onClick={() => onEditBtnClick(item.SiteId)} />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </SlideFade>
  )
}
