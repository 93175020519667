import React from 'react'
import { Box, Text } from '@chakra-ui/react'

type LotListItemProps = {
  bg: string
  borderColor: string
  lotNumber: string
  RFIDCount: string
  onClickCallback?: () => void
}

export const LotListItem = ({
  bg,
  borderColor,
  lotNumber,
  RFIDCount,
  onClickCallback,
}: LotListItemProps): JSX.Element => {
  return (
    <Box
      d="flex"
      w="full"
      h="48px"
      py={1}
      borderBottom="2px"
      borderColor={borderColor}
      bg={bg}
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClickCallback}
      _hover={{ transform: 'scale(1.01)' }}
    >
      <Box h="full" d="flex" justifyContent="space-between" alignItems="center" w="full">
        <Text px={2} fontSize="14px" fontWeight="400" lineHeight="20px">
          {lotNumber}
        </Text>
        <Text pr={16} fontSize="12px" fontWeight="400" lineHeight="16px">
          {RFIDCount}
        </Text>
      </Box>
    </Box>
  )
}
