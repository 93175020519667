import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  Dashboard,
  Sites,
  CreateSite,
  EditSite,
  UsersList,
  CreateUser,
  EditUser,
  FourOFour,
  HomePage,
  ProtectedRoute,
} from './index'

export const routes = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/404" component={FourOFour} />
      <Route path="/sites">
        <Switch>
          <ProtectedRoute exact path="/sites/users/createuser" component={CreateUser} />
          <ProtectedRoute exact path="/sites/users/edituser" component={EditUser} />
          <ProtectedRoute exact path="/sites/users" component={UsersList} />
          <ProtectedRoute exact path="/sites/createsite" component={CreateSite} />
          <ProtectedRoute exact path="/sites/editsite" component={EditSite} />
          <ProtectedRoute exact path="/sites" component={Sites} />
        </Switch>
      </Route>
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <Route path="/" component={HomePage} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
