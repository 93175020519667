import React, { useEffect, useState } from 'react'
import { Box, SlideFade, Text, useColorModeValue, VStack, Center, Spinner } from '@chakra-ui/react'

import { RFIDListItem } from './RFIDListItem'
import { useHistory } from 'react-router-dom'
import { LotDetailResponseData } from 'types/ApiResponse.types'
import { makePostCall } from 'services/DashboardAPIs'

type RFIDTableProps = {
  backgrounds: { bg: string; subBg: string }
  selectedLot: { SiteId: number; RfidLotNo: string; LotCount: number }
  siteId: number
  daysFilterValue: { days: number; startDate?: string; endDate?: string }
}

export function RFIDTable({
  backgrounds: { bg, subBg },
  selectedLot,
  siteId,
  daysFilterValue,
}: RFIDTableProps): JSX.Element {
  const history = useHistory()
  const borderColor = useColorModeValue('#C1CBD9', 'gray.50')
  const headerColor = useColorModeValue('#626C7A', 'white')
  const [data, setData] = useState<LotDetailResponseData | null>(null)
  const [showloader, setShowloader] = useState(true)

  useEffect(() => {
    let reqBody: unknown = {
      SiteId: siteId.toString(),
      Days: daysFilterValue.days.toString(),
      LotNo: selectedLot.RfidLotNo.toString(),
      TimeZoneOffset: new Date().getTimezoneOffset(),
    }

    if (daysFilterValue.startDate)
      reqBody = {
        SiteId: siteId.toString(),
        LotNo: selectedLot.RfidLotNo.toString(),
        StartDate: daysFilterValue.startDate,
        EndDate: daysFilterValue.endDate,
        TimeZoneOffset: new Date().getTimezoneOffset(),
      }

    const tempAsyncFunc = async () => {
      try {
        const res = await makePostCall<LotDetailResponseData>('rfid/lotdetail', reqBody, history)
        setData(res)
      } catch (err) {
        setData(null)
      } finally {
        setShowloader(false)
      }
    }

    if (!showloader) setShowloader(true)
    tempAsyncFunc()
  }, [siteId, daysFilterValue.days, selectedLot])

  if (showloader)
    return (
      <Center width="full" h="50%">
        <Spinner thickness="4px" speed="0.65s" emptyColor={bg} color="blue.500" size="xl" />
      </Center>
    )

  return (
    <SlideFade in={true} offsetY={40}>
      <Box overflow="auto" d="flex" w="full" h="full" bg={subBg}>
        <VStack w="full" spacing="0px">
          <Box d="flex" alignItems="center" w="full" h={6} pl={1} borderWidth="1px" borderColor={borderColor} bg={bg}>
            {['RFID', 'TIME STAMP', 'REFERENCE NO', 'DEVICE'].map((item) => (
              <Text key={item} flex="1" fontSize="10px" lineHeight="12px" fontWeight="700" color={headerColor}>
                {item}
              </Text>
            ))}
          </Box>
          {data &&
            data?.Data?.map((item, index) => (
              <RFIDListItem
                key={`RFIDListItem_${index}`}
                borderColor={bg}
                RFIDStr={item.RfidId}
                timeStamp={item.CreatedDate}
                referenceNo={item.ReferenceNo}
                device={item.Device}
              />
            ))}
        </VStack>
      </Box>
    </SlideFade>
  )
}
