import Cookies from 'universal-cookie'
import download from 'downloadjs'

import { APP } from 'assets/Constants'

const exportExcelEndpoint = `${APP.API_URL}site/GetSiteExcel`

export const DownloadExcel = (reqBody: unknown): Promise<unknown> => {
  const cookies = new Cookies()

  return fetch(exportExcelEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(APP.COOKIE_NAME),
    },
    body: JSON.stringify(reqBody),
  })
    .then((res) => {
      let filename =
        process.env.NODE_ENV === 'production'
          ? res.headers.get('Content-Disposition')
          : `attachment; filename="Only on Dev env: filename will come from Content-Disposition header which may or may not work due to CORS.XLSX"; filename*=UTF-8''format%20without%20site%20selection_24_02_2021_05_54_06.XLSX`
      if (filename) filename = filename.split('"')[1]
      return { res, filename }
    })
    .then(async ({ res, filename }) => {
      return {
        blob: await res.blob(),
        filename,
      }
    })
    .then(({ blob, filename }) =>
      download(
        blob,
        filename ? filename : 'RFID SMARTDRAPES sites data.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.shee',
      ),
    )
}
