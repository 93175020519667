import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Input,
  HStack,
  Button,
  RadioGroup,
  Stack,
  Radio,
  Text,
  useDisclosure,
  SlideFade,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { ConfirmationModal } from 'components'
import { APP } from 'assets/Constants'
import { SiteListResponseData } from 'types/ApiResponse.types'

const sitesListEndpoint = `${APP.API_URL}site/list`
const sitesSaveEndpoint = `${APP.API_URL}site/save`

function useIdQuery() {
  return new URLSearchParams(useLocation().search).get('id')
}

export const EditSite = (): JSX.Element => {
  const cookies = new Cookies()
  const history = useHistory()

  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const [values, setValues] = useState({
    SiteName: '',
    Country: '',
    City: '',
    State: '',
    PinCode: '',
  })
  const { SiteName, Country, City, State, PinCode } = values
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isDeactivateOpen, onOpen: onDeactivateOpen, onClose: onDeactivateClose } = useDisclosure()
  const [radioBtnValue, setRadioBtnValue] = useState('1')
  const [radioBtnStatus, setRadioBtnStatus] = useState(radioBtnValue === '1' ? 'activate' : 'deactivate')
  const [isSaveBtnDisabled, setisSaveBtnDisabled] = useState(false)
  const [isAPICalled, setIsAPICalled] = useState(false)
  const queryID = useIdQuery()

  const onCancelClick = () => history.goBack()
  const onSitenameValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, SiteName: event.target.value })
  const onCountryValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, Country: event.target.value })
  const onStateValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, State: event.target.value })
  const onCityValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, City: event.target.value })
  const onPincodeValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, PinCode: event.target.value })

  const onSuccess = () => {
    if (isAPICalled) return
    setIsAPICalled(true)
    fetch(sitesSaveEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
      body: JSON.stringify({ ...values, IsActive: radioBtnValue === '1' ? 'true' : 'false', SiteId: queryID }),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsAPICalled(false)
        if (res.Response.ResponseCode === '1') history.goBack()
        else {
          setIsAPICalled(false)
          onClose()
          alert(`${res.Response.ResponseMessage}!!!`)
        }
      })
      .catch((error) => alert(error.message))
  }

  const resertRadioBtnValues = () => {
    setRadioBtnValue(radioBtnValue === '1' ? '2' : '1')
    onDeactivateClose()
  }

  const onRadioButtonValueChange = (val: string) => {
    setRadioBtnValue(val)
    setRadioBtnStatus(val === '1' ? 'activate' : 'deactivate')
    onDeactivateOpen()
  }

  useEffect(() => {
    if (values.SiteName && values.State && values.City && values.Country && values.PinCode) setisSaveBtnDisabled(false)
    else setisSaveBtnDisabled(true)
  }, [values])

  useEffect(() => {
    fetch(sitesListEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    })
      .then((res) => res.json())
      .then((res: SiteListResponseData) => {
        if (res.Response.ResponseCode === '1') {
          const result = res.Data?.Users?.find((i) => i.SiteId.toString() === queryID)
          if (result) {
            setValues({
              SiteName: result.SiteName,
              Country: result.Country,
              City: result.City,
              State: result.State,
              PinCode: result.PinCode,
            })
            setRadioBtnValue(result.IsActive ? '1' : '2')
          }
        }
      })
  }, [queryID])

  return (
    <SlideFade in={true} offsetY={40}>
      <Flex h="full" flexDirection="column" bg={bg}>
        <Box h="32px" bg={subBg} px={10} pt="2px" alignItems="center" flexDirection="column" mt="1.5px">
          <Breadcrumb spacing="8px" separator={<ChevronRightIcon ml={4} color="#F251B7" />}>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" as={NavLink} to="/sites">
                Sites
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="600" as={NavLink} to="#">
                Edit Site
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
          <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
            <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
              Edit site
            </Heading>
          </Flex>
          <Box h="full" bg={subBg}>
            <Box w="full" py={10} px={4} borderColor={bg} borderTopWidth={2} borderBottomWidth={2} alignItems="center">
              <Input
                borderColor="none"
                borderWidth="0px"
                borderBottom="1px"
                borderBottomColor={bg}
                borderRadius="0px"
                px={0}
                focusBorderColor="none"
                w="50vw"
                value={SiteName}
                onChange={onSitenameValueChange}
                size="md"
                contentEditable="false"
                placeholder="Site Name *"
              />
              <HStack mt={12} spacing={4}>
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={Country}
                  onChange={onCountryValueChange}
                  size="md"
                  placeholder="Site Country *"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={State}
                  onChange={onStateValueChange}
                  size="md"
                  placeholder="State *"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={City}
                  onChange={onCityValueChange}
                  size="md"
                  placeholder="City *"
                />
                <Input
                  borderColor="none"
                  borderWidth="0px"
                  borderBottom="2px"
                  borderBottomColor={bg}
                  borderRadius="0px"
                  px={0}
                  focusBorderColor="none"
                  value={PinCode}
                  onChange={onPincodeValueChange}
                  size="md"
                  placeholder="Pincode *"
                />
              </HStack>
              <RadioGroup mt={14} onChange={onRadioButtonValueChange} value={radioBtnValue}>
                <Stack spacing={4} direction="row">
                  <Text mr={7}>Status</Text>
                  <Radio _focus={{ boxShadow: '' }} colorScheme="gray" value="1">
                    Active
                  </Radio>
                  <Radio _focus={{ boxShadow: '' }} colorScheme="gray" value="2">
                    Inactive
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box p={8} w="full" d="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                bg={bg}
                _hover={{ bg: bg }}
                _active={{ bg: bg }}
                _focus={{ boxShadow: '' }}
                fontSize="14px"
                fontWeight="400"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                w="120px"
                h="48px"
                borderRadius="2px"
                mx={4}
                bg="#0050F2"
                _hover={{ bg: '#0050F2', boxShadow: '' }}
                _active={{ bg: '#0050F2', boxShadow: '' }}
                _focus={{ boxShadow: '' }}
                color="white"
                fontSize="14px"
                fontWeight="600"
                onClick={onOpen}
                disabled={isSaveBtnDisabled}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        <ConfirmationModal
          message="Are you sure you want to save the changes?"
          isOpen={isOpen}
          onClose={onClose}
          onYes={onSuccess}
        />
        <ConfirmationModal
          message={`Are you sure you want to ${radioBtnStatus} the site?`}
          isOpen={isDeactivateOpen}
          onClose={resertRadioBtnValues}
          onYes={onDeactivateClose}
        />
      </Flex>
    </SlideFade>
  )
}
