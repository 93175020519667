import Cookies from 'universal-cookie'

import { APP } from 'assets/Constants'

const _api: { [key: string]: string } = {
  'site/get': `${APP.API_URL}site/get`,
  'rfid/lotsize': `${APP.API_URL}rfid/lotsize`,
  'rfid/lotdetail': `${APP.API_URL}rfid/lotdetail`,
}

export const makePostCall = <T>(endPoint: string, reqBody: unknown, history: unknown): Promise<T> => {
  const cookies = new Cookies()

  return fetch(_api[endPoint], {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(APP.COOKIE_NAME),
    },
    body: JSON.stringify(reqBody),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.Response.ResponseCode === '1') return res
      else if (res.Response.ResponseMessage.match(/^(Invalid Request|Invalid Token|Expired Token)/)) {
        cookies.remove(APP.COOKIE_NAME)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        history.push('/')
      } else throw new Error(res.Response.ResponseCode)
    })
}
