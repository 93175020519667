import React from 'react'
import { Box, Heading, useColorModeValue, Flex, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { SitesTable } from './SitesTable'

export const Sites: React.FC = () => {
  const history = useHistory()

  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')

  const onCreateSiteClick = () => {
    history.push('sites/createsite')
  }

  return (
    <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
      <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
        <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
          Sites
        </Heading>
        <Button
          bg="#0050F2"
          _hover={{ bg: '#0050F2' }}
          _active={{ bg: '#0050F2' }}
          _focus={{ boxShadow: '' }}
          color="white"
          fontSize="14px"
          fontWeight="600"
          size="md"
          mx={2}
          w="120px"
          h="48px"
          borderRadius="2px"
          onClick={onCreateSiteClick}
        >
          Create Site
        </Button>
      </Flex>
      <Box h="full" bg={subBg} overflow="auto">
        <SitesTable backgrounds={{ bg, subBg }} />
      </Box>
    </Box>
  )
}
