import React from 'react'
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { NavLink, useHistory, useLocation } from 'react-router-dom'

import { UsersTable } from './UsersTable'
import { APP } from 'assets/Constants'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const UsersList = (): JSX.Element => {
  const bg = useColorModeValue('gray.50', 'gray.700')
  const subBg = useColorModeValue('white', 'gray.800')
  const history = useHistory()
  const query = useQuery()
  const SiteId = query.get('id')
  const SiteName = query.get('name')

  if (!SiteId || !SiteName) history.push('/sites')

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onCreateUserClick = () => {
    openInNewTab(APP.CREATE_USER_URL)
    history.push(`users/createuser?id=${SiteId}`)
  }

  return (
    <Flex h="full" flexDirection="column" bg={bg}>
      <Box h="32px" bg={subBg} px={10} pt="2px" alignItems="center" flexDirection="column" mt="1.5px">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon ml={4} color="#F251B7" />}>
          <BreadcrumbItem>
            <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="400" as={NavLink} to="/sites">
              Sites
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink fontSize="14px" lineHeight="20px" fontWeight="600" as={NavLink} to="#">
              Users
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <Box d="flex" h="full" p={2} bg={bg} flexDirection="column">
        <Flex w="full" h={16} pl={8} alignItems="center" justifyContent="space-between" bg={subBg}>
          <Heading fontSize="24px" fontWeight="350" lineHeight="36px">
            {SiteName}
          </Heading>
          <Button
            bg="#0050F2"
            _hover={{ bg: '#0050F2' }}
            _active={{ bg: '#0050F2' }}
            _focus={{ boxShadow: '' }}
            color="white"
            fontSize="14px"
            fontWeight="600"
            size="md"
            mx={2}
            w="120px"
            h="48px"
            borderRadius="2px"
            onClick={onCreateUserClick}
          >
            Create User
          </Button>
        </Flex>
        <Box h="full" bg={subBg}>
          {SiteId && <UsersTable backgrounds={{ bg, subBg }} SiteId={SiteId} />}
        </Box>
      </Box>
    </Flex>
  )
}
