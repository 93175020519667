import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: 'Segoe UI, SegoeUIMac',
    body: 'Segoe UI, SegoeUIMac',
    mono: 'Segoe UI, SegoeUIMac',
  },
  colors: {
    gray: {
      50: '#E4E9F0',
    },
  },
})

export default theme
