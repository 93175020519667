import React, { useEffect, useState } from 'react'
import { Box, Center, SlideFade, Spinner, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { LotListItem } from './LotListItem'
import { LotSizeResponseData } from 'types/ApiResponse.types'
import { makePostCall } from 'services/DashboardAPIs'

type LotTableProps = {
  backgrounds: { bg: string; subBg: string }
  siteId: number
  daysFilterValue: { days: number; startDate?: string; endDate?: string }
  setSelectedLot: (item: { SiteId: number; RfidLotNo: string; LotCount: number } | null) => void
}
export function LotTable({
  backgrounds: { bg, subBg },
  siteId,
  daysFilterValue,
  setSelectedLot,
}: LotTableProps): JSX.Element {
  const history = useHistory()

  const [selectedLotListItem, setSelectedLotListItem] = useState(0)
  const borderColor = useColorModeValue('#C1CBD9', 'gray.50')
  const headerColor = useColorModeValue('#626C7A', 'white')
  const [data, setData] = useState<LotSizeResponseData | null>(null)
  const [showloader, setShowloader] = useState(true)

  const changeSelectedLotListItem = (index: number) => {
    setSelectedLotListItem(index)
    setSelectedLot(data?.Data[index] || null)
  }

  useEffect(() => {
    let reqBody: unknown = {
      SiteId: siteId.toString(),
      Days: daysFilterValue.days.toString(),
      TimeZoneOffset: new Date().getTimezoneOffset(),
    }

    if (daysFilterValue.startDate)
      reqBody = {
        SiteId: siteId.toString(),
        StartDate: daysFilterValue.startDate,
        EndDate: daysFilterValue.endDate,
        TimeZoneOffset: new Date().getTimezoneOffset(),
      }

    const tempAsyncFunc = async () => {
      try {
        const res = await makePostCall<LotSizeResponseData>('rfid/lotsize', reqBody, history)
        setData(res)
      } catch (err) {
        setData(null)
      } finally {
        setShowloader(false)
      }
    }

    if (!showloader) setShowloader(true)
    tempAsyncFunc()
  }, [siteId, daysFilterValue.days])

  useEffect(() => {
    if (data?.Data?.length) changeSelectedLotListItem(0)
  }, [data])

  if (showloader)
    return (
      <Center width="full" h="50%">
        <Spinner thickness="4px" speed="0.65s" emptyColor={bg} color="blue.500" size="xl" />
      </Center>
    )

  return (
    <SlideFade in={true} offsetY={40}>
      <Box overflow="hidden" d="flex" w="full" h="100%" bg={subBg}>
        {data && (
          <VStack w="full" spacing="0px">
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              w="full"
              h={6}
              borderWidth="1px"
              borderColor={borderColor}
              bg={bg}
            >
              <Text px={2} fontSize="10px" lineHeight="12px" fontWeight="700" color={headerColor}>
                LOT NUMBER
              </Text>
              <Text pr={14} fontSize="10px" lineHeight="12px" fontWeight="700" color={headerColor}>
                RFID COUNT
              </Text>
            </Box>
            {data &&
              data?.Data?.map((item, index) => (
                <LotListItem
                  key={`lotlistitem_${index}`}
                  onClickCallback={() => changeSelectedLotListItem(index)}
                  bg={selectedLotListItem !== index ? subBg : bg}
                  borderColor={bg}
                  lotNumber={item.RfidLotNo}
                  RFIDCount={item.LotCount.toString()}
                />
              ))}
          </VStack>
        )}
      </Box>
    </SlideFade>
  )
}
