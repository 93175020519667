import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Image, Box, Text, Center, Spinner, SlideFade } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import editIcon from 'assets/images/edit_icn.svg'
import { APP } from 'assets/Constants'
import { UserListResponseData } from 'types/ApiResponse.types'

type UsersTableProps = { backgrounds: { bg: string; subBg: string }; SiteId: string }

const usersListEndpoint = `${APP.API_URL}user/list?siteid=`

export function UsersTable({ backgrounds: { bg, subBg }, SiteId }: UsersTableProps): JSX.Element {
  const cookies = new Cookies()
  const history = useHistory()

  const [siteData, setSiteData] = useState<UserListResponseData | null>(null)
  const [showloader, setShowloader] = useState(true)

  const onEditBtnClick = (loginid: string, siteid: number) =>
    history.push(`users/edituser?siteid=${siteid}&loginid=${loginid}`)

  useEffect(() => {
    if (!showloader) setShowloader(true)
    fetch(usersListEndpoint + SiteId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookies.get(APP.COOKIE_NAME),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Response.ResponseCode === '1') setSiteData(res)
        else if (res.Response.ResponseMessage.match(/^(Invalid Request|Invalid Token)/)) {
          cookies.remove(APP.COOKIE_NAME)
          history.push('/')
        }
      })
      .catch((err) => {
        setSiteData(null)
        console.log(err)
      })
      .finally(() => setShowloader(false))
  }, [])

  if (showloader)
    return (
      <Center>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.700" color="blue.500" size="xl" />
      </Center>
    )

  return (
    <SlideFade in={true} offsetY={40}>
      <Table variant="simple">
        <Thead bg={bg} h="24px">
          <Tr fontWeight="700" fontSize="10px" lineHeight="12px">
            <Th w="25%" borderRightWidth="2px" borderRightColor={subBg} py="4px">
              FIRST NAME
            </Th>
            <Th w="25%" borderRightWidth="2px" borderRightColor={subBg} py="4px">
              LAST NAME
            </Th>
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              LOGIN ID
            </Th>
            <Th borderRightWidth="2px" borderRightColor={subBg} py="4px">
              Status
            </Th>
            <Th py="4px">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {siteData?.Data &&
            siteData.Data?.Users?.map((item, index) => (
              <Tr key={`usertable_${index}`} p="0px">
                <Td pl={6} py="0" h="48px">
                  {item.FirstName}
                </Td>
                <Td pl={6} py="0" h="48px">
                  {item.LastName}
                </Td>
                <Td pl={6} py="0" h="48px">
                  {item.LoginId}
                </Td>
                <Td pl={6} py="0">
                  <Box borderRadius={2} h="24px" w="80px" bg={item.IsActive ? 'green.100' : 'red.100'}>
                    <Center>
                      <Text
                        zIndex="2"
                        pt="2px"
                        color={item.IsActive ? '#29C192' : '#ED5353'}
                        fontWeight="700"
                        fontSize="10px"
                      >
                        {item.IsActive ? 'ACTIVE' : 'DEACTIVE'}
                      </Text>
                    </Center>
                  </Box>
                </Td>
                <Td pl={6} py="0" h="48px">
                  <Image src={editIcon} cursor="pointer" onClick={() => onEditBtnClick(item.LoginId, item.SiteId)} />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </SlideFade>
  )
}
