/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Button,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'
import Calendar from 'react-calendar'

import 'react-calendar/dist/Calendar.css'
import './custom.css'
import calender_icn from 'assets/images/calender_icn.svg'

type ButtonBarProps = {
  buttonBarBG: string
  backgrounds: { bg: string; subBg: string }
  changeDaysFilterValue: (obj: { days: number; startDate?: string; endDate?: string }) => void
}

function formatDate(date: Date) {
  const d = date // new Date(date)
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('-')
}

export const ButtonBar = ({ buttonBarBG, changeDaysFilterValue }: ButtonBarProps): JSX.Element => {
  const [selectedFilter, setSelectedFilter] = useState(1)
  const [fromValue, setFromValue] = useState<Date | Date[] | null>(null)
  const [toValue, setToValue] = useState<Date | Date[] | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  const [isFromPopoverOpen, setIsFromPopoverOpen] = useState(false)
  const fromPopoverOpen = () => setIsFromPopoverOpen(!isFromPopoverOpen)
  const fromPopoverclose = () => setIsFromPopoverOpen(false)

  const [isToPopoverOpen, setIsToPopoverOpen] = useState(false)
  const toPopoverOpen = () => setIsToPopoverOpen(!isToPopoverOpen)
  const toPopoverclose = () => setIsToPopoverOpen(false)

  const [fromStr, setFromStr] = useState('Date')
  const [toStr, setToStr] = useState('Date')

  const textColorBasedonMode = useColorModeValue('#353D45', 'white')

  const onFromDateChange = (v: Date | Date[]) => {
    if (toValue && toValue < v) {
      alert('From Date should not be greater than to date')
      return
    }
    if (v) {
      setFromValue(v)
      setFromStr(formatDate(v as Date))
      if (isFromPopoverOpen) {
        fromPopoverclose()
        toPopoverOpen()
      }
    }
  }

  const onToDateChange = (v: Date | Date[]) => {
    if (fromValue && fromValue > v) {
      alert('From Date should not be greater than to date')
      return
    }
    if (v) {
      setToValue(v)
      setToStr(formatDate(v as Date))
      if (isFromPopoverOpen) toPopoverOpen()
    }
    if (isToPopoverOpen) toPopoverclose()
  }

  const onApplyClicked = () => {
    changeDaysFilterValue({
      days: 0,
      startDate: fromStr,
      endDate: toStr,
    })
    close()
  }

  const onCloseClicked = () => {
    setFromStr('Date')
    setFromValue(null)
    setToValue(null)
    setToStr('Date')
    close()
  }

  return (
    <Box
      d="flex"
      alignItems="center"
      justifyContent="space-evenly"
      h="32px"
      w="438px"
      bg={buttonBarBG}
      my="2"
      mr="2"
      borderWidth="1px"
      borderRadius="3px"
      borderColor="#828D9E"
      cursor="pointer"
    >
      <Box
        h="full"
        w="full"
        defaultValue="0"
        textAlign="center"
        pt="3px"
        borderRight="1px"
        borderColor="#828D9E"
        bg={selectedFilter === 0 ? '#828D9E' : 'transparent'}
        onClick={() => {
          close()
          setSelectedFilter(0)
          changeDaysFilterValue({ days: 0, startDate: '', endDate: '' })
        }}
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Text userSelect="none" color={selectedFilter === 0 ? 'white' : ''}>
          Today
        </Text>
      </Box>
      <Box
        h="full"
        w="full"
        textAlign="center"
        pt="3px"
        bg={selectedFilter === 1 ? '#828D9E' : 'transparent'}
        borderRight="1px"
        borderColor="#828D9E"
        onClick={() => {
          close()
          setSelectedFilter(1)
          changeDaysFilterValue({ days: 7, startDate: '', endDate: '' })
        }}
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Text userSelect="none" color={selectedFilter === 1 ? 'white' : ''}>
          Last 7 Days
        </Text>
      </Box>
      <Box
        h="full"
        w="full"
        textAlign="center"
        pt="3px"
        bg={selectedFilter === 2 ? '#828D9E' : 'transparent'}
        borderRight="1px"
        borderColor="#828D9E"
        onClick={() => {
          close()
          setSelectedFilter(2)
          changeDaysFilterValue({ days: 30, startDate: '', endDate: '' })
        }}
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Text userSelect="none" color={selectedFilter === 2 ? 'white' : ''}>
          Last 30 Days
        </Text>
      </Box>
      <Popover placement="bottom-end" closeOnBlur={false} isOpen={isOpen} onClose={close}>
        <Box
          h="full"
          w="full"
          textAlign="center"
          pt="3px"
          bg={selectedFilter === 3 ? '#828D9E' : 'transparent'}
          onClick={() => {
            open()
            setSelectedFilter(3)
          }}
          _hover={{ transform: 'scale(1.02)' }}
        >
          <Text userSelect="none" color={selectedFilter === 3 ? 'white' : ''}>
            Custom
          </Text>
        </Box>
        <PopoverTrigger>
          <Box position="absolute" right={14} mt={8} />
        </PopoverTrigger>
        <PopoverContent _active={{ boxShadow: '' }} _focus={{ boxShadow: '', outline: '0px' }}>
          <PopoverArrow />
          <PopoverBody h="144px" w="323px" p="0">
            <Flex h="144px" flexDirection="column">
              <Flex pt={2} alignItems="center" flex="1" justifyContent="space-evenly">
                <Flex px={2} mb={2} flex="1" flexDirection="column">
                  <Text userSelect="none" fontSize="14px" lineHeight="20px">
                    From
                  </Text>
                  <Popover placement="bottom" closeOnBlur={false} isOpen={isFromPopoverOpen} onClose={fromPopoverclose}>
                    <PopoverTrigger>
                      <Box
                        d="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottomColor="#828D9E"
                        borderBottomWidth="1px"
                        py={2}
                        onClick={() => {
                          toPopoverclose()
                          fromPopoverOpen()
                        }}
                      >
                        <Text
                          userSelect="none"
                          color={fromStr === 'Date' ? '#828D9E' : textColorBasedonMode}
                          fontSize="14px"
                          lineHeight="20px"
                        >
                          {fromStr}
                        </Text>
                        <Image src={calender_icn} />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent _active={{ boxShadow: '' }} _focus={{ boxShadow: '', outline: '0px' }}>
                      <PopoverArrow />
                      <PopoverBody>
                        <Calendar onChange={onFromDateChange} maxDate={new Date()} value={fromValue} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
                <Flex px={2} mb={2} flex="1" flexDirection="column">
                  <Text userSelect="none" fontSize="14px" lineHeight="20px">
                    To
                  </Text>
                  <Popover
                    placement="bottom-start"
                    closeOnBlur={false}
                    isOpen={isToPopoverOpen}
                    onClose={toPopoverclose}
                  >
                    <PopoverTrigger>
                      <Box
                        d="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottomColor="#828D9E"
                        borderBottomWidth="1px"
                        py={2}
                        onClick={() => {
                          fromPopoverclose()
                          toPopoverOpen()
                        }}
                      >
                        <Text
                          userSelect="none"
                          color={toStr === 'Date' ? '#828D9E' : textColorBasedonMode}
                          fontSize="14px"
                          lineHeight="20px"
                        >
                          {toStr}
                        </Text>
                        <Image src={calender_icn} />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent _active={{ boxShadow: '' }} _focus={{ boxShadow: '', outline: '0px' }}>
                      <PopoverArrow />
                      <PopoverBody>
                        <Calendar onChange={onToDateChange} maxDate={new Date()} value={toValue} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
              </Flex>
              <Flex flex="1" w="full" justifyContent="center" alignItems="center">
                <Button
                  h="32px"
                  w="100px"
                  _focus={{ bg: '' }}
                  _hover={{ bg: '' }}
                  _active={{ bg: '' }}
                  borderRadius="3px"
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="400"
                  onClick={onCloseClicked}
                >
                  Cancel
                </Button>
                <Button
                  ml="20px"
                  h="32px"
                  w="100px"
                  borderRadius="3px"
                  bg="#828D9E"
                  _focus={{ bg: '' }}
                  _hover={{ bg: '' }}
                  _active={{ bg: '' }}
                  disabled={fromStr === 'Date' || toStr === 'Date' ? true : false}
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="600"
                  onClick={onApplyClicked}
                >
                  Apply
                </Button>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
