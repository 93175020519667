// DEV
// export const APP = {
//   API_URL: 'https://sdportal.dev.zeiss.com/',
//   OAUTH_URL:
//     'https://id-ip-test.zeiss.com/OAuth/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=45e8b0c7-25a4-4be4-a24a-98785ddc83dc&redirect_uri=https://sdportal.dev.zeiss.com/login&response_type=id_token&scope=openid%20profile&response_mode=form_post',
//   LOGOUT_URL:
//     'https://id-ip-test.zeiss.com/OAuth/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=https://sdportal.dev.zeiss.com/',
//   CREATE_USER_URL: 'https://id-mgmt-test.zeiss.com/Accounts/Index',
//   COOKIE_NAME: 'api_tempkey',
// }

// STAGE
// export const APP = {
//   API_URL: 'https://sdportal.test.zeiss.com/',
//   OAUTH_URL:
//     'https://id-ip-stage.zeiss.com/OAuth/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=9cae07d3-1fd9-4dae-bc00-b7525ab80024&redirect_uri=https://sdportal.test.zeiss.com/login&response_type=id_token&scope=openid%20profile&response_mode=form_post',
//   LOGOUT_URL:
//     'https://id-ip-stage.zeiss.com/OAuth/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=https://sdportal.test.zeiss.com/',
//   CREATE_USER_URL: 'https://id-mgmt-stage.zeiss.com/Accounts/Index',
//   COOKIE_NAME: 'api_tempkey',
// }

// PROD
// export const APP = {
//   API_URL: 'https://smartdrapes.zeiss.com/',
//   OAUTH_URL:
//     'https://id-ip.zeiss.com/OAuth/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=c029454a-7507-45af-92cb-fa9ec3616519&redirect_uri=https%3A%2F%2Fsmartdrapes.zeiss.com%2Flogin&response_type=id_token&scope=openid%20profile&response_mode=form_post',
//   LOGOUT_URL:
//     'https://id-ip.zeiss.com/OAuth/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=https://smartdrapes.zeiss.com/',
//   CREATE_USER_URL: 'https://id-mgmt.zeiss.com/Accounts/Index',
//   COOKIE_NAME: 'api_tempkey',
// }

// PROD CHINA
export const APP = {
  API_URL: 'https://smartdrapes.zeiss.com.cn/',
  OAUTH_URL:
    'https://id-ip.zeiss.com/OAuth/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=ad1c2a89-617d-4a07-9fcc-ced689ed737a&redirect_uri=https://smartdrapes.zeiss.com.cn/login&response_type=id_token&scope=openid%20profile&response_mode=form_post',
  LOGOUT_URL:
    'https://id-ip.zeiss.com/OAuth/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=https://smartdrapes.zeiss.com.cn/',
  CREATE_USER_URL: 'https://id-mgmt.zeiss.com/Accounts/Index',
  COOKIE_NAME: 'api_tempkey',
}
